import { request } from './request'

export function getSubjectList() {
  return request({
    url: '/api/subject/list/'
  })
}

export function getAdver(ad) {
  return request({
    url: '/api/solgan/list/' + ad
  })
}

export function getHotLabels() {
  return request({
    url: '/api/label/list'
  })
}
export function getRecomdVideos() {
  return request({
    url: '/api/subjectstagecourse/course/popular'
  })
}
