import { request } from './request'

export function getHotLabels(id) {
  return request({
    url: `/api/label/list/all${id ? 'subjectId=' + id : ''}`
  })
}

export function getSubjectStageCourses(data) {
  return request({
    url: '/api/subjectstagecourse/list',
    method: 'post',
    data
  })
}
