<template>
  <div class="classify_wrapper">
    <div class="main">
      <div class="navControl">
        <div class="classify subjectClaasify marginTop44">
          <span>学科分类：</span>
          <div :class="{ autoHeight: expandHeight }" class="content">
            <ul>
              <li
                v-for="(item, index) in subjectArr"
                :class="{ active: item.id == subjectId }"
                @click="chooseSubject(item, index)"
                v-html="item.subjectName"
              ></li>
            </ul>
          </div>
          <div @click="unfoldLabel" class="arrow">
            <span v-html="expandHeight ? '收起' : '展开'"></span>
          </div>
        </div>
        <div class="classify subjectClaasify">
          <span>难度分类：</span>
          <div
            :class="{ autoHeightSecond: expandHeightSecond }"
            class="content"
          >
            <ul class="levelWrap">
              <li
                @click="chooseLevel(null)"
                :class="{ active: curLevelIndex == null }"
              >
                全部
              </li>
              <li
                @click="chooseLevel(1)"
                :class="{ active: curLevelIndex === 1 }"
              >
                <i class="level1B"></i>
                <span><strong>入门：</strong>零基础小白入门</span>
              </li>
              <li
                @click="chooseLevel(2)"
                :class="{ active: curLevelIndex === 2 }"
              >
                <b class="level2B"></b>
                <span><strong>中级：</strong>求职进阶利器</span>
              </li>
              <li
                @click="chooseLevel(3)"
                :class="{ active: curLevelIndex === 3 }"
              >
                <b class="level3B"></b>
                <span><strong>高级：</strong>高效赋能提升</span>
              </li>
              <li
                @click="chooseLevel(4)"
                :class="{ active: curLevelIndex === 4 }"
              >
                <b class="level4B"></b>
                <span><strong>项目：</strong>名企真实案例驱动</span>
              </li>
              <li
                @click="chooseLevel(5)"
                :class="{ active: curLevelIndex === 5 }"
              >
                <b class="level5B"></b>
                <span><strong>面试：</strong>大厂求职攻略</span>
              </li>
            </ul>
          </div>
        </div>
        <div class="classify label-contain">
          <span>技术点分类：</span>
          <div :class="{ autoHeightThird: expandHeightThird }" class="content">
            <ul @click="chooseItemLabel">
              <li
                ref="lidom"
                v-for="(item, index) in _hotLableList"
                :class="{
                  active: item.id == labelId,
                }"
                v-html="item.lableName"
                :data-labelid="item.id"
                :data-labelidx="index"
              ></li>
            </ul>
          </div>
          <div @click="unfoldLabelThird" class="arrow">
            <span v-html="expandHeightThird ? '收起' : '展开'"></span>
          </div>
        </div>
      </div>

      <div id="roadLine" v-if="videoArr.length > 0" class="video">
        <div class="inner">
          <div class="content">
            <div class="course">
              <a
                :href="`/video/${item.subjectId}/${item.id}` + addHrefSearch()"
                target="_blank"
                @click="jumptoDetailpage(item)"
                class="item"
                v-for="item in videoArr"
              >
                <div class="container">
                  <img :src="item.url" alt="" />
                </div>
                <div v-html="item.courseName" class="name"></div>
                <div class="message">
                  <span>
                    <img src="~@/assets/images/icons/number.png" alt="" />
                    <i v-html="item.studyNum"></i> 人学习</span
                  >
                  <em :class="'levelColor' + item.level">
                    <span
                      :src="
                        '../../assets/images/icons/level' +
                        (item.level || 3) +
                        '.png'
                      "
                      alt=""
                    ></span>
                    <i v-html="formatLevel(item.level)"></i>
                  </em>
                </div>
              </a>
            </div>
          </div>
        </div>
      </div>
      <el-empty description="暂无内容" v-else></el-empty>

      <el-pagination
        v-if="totalCount > 30"
        @current-change="changePage"
        background
        :page-size="pageSize"
        layout="prev, pager, next"
        :total="totalCount"
        :current-page="pageNum"
      >
      </el-pagination>
    </div>
  </div>
</template>

<script>
// import "assets/css/classify.less";

import { getHotLabels, getSubjectStageCourses } from "network/classify";
import { getSubjectList } from "network/common";
import { mapState } from "vuex";

export default {
  name: "Classify",
  components: {},
  computed: {
    //重新计算调整标签列表：热门字段放在技术点分类前显示
    _hotLableList() {
      //热门字段
      const hotLabels = [
        "Java基础",
        "Java进阶",
        "web前端基础",
        "web前端进阶",
        "python基础",
        "python进阶",
        "UI设计",
        "UI基础",
      ];
      //过滤修改
      for (let i in hotLabels) {
        for (let j in this.hotLableList) {
          if (this.hotLableList[j].lableName === hotLabels[i]) {
            hotLabels[i] = this.hotLableList[j];
            this.hotLableList.splice(j, 1);
          }
        }
      }
      //增加‘全部’标签
      let tag_all = {
        id: null,
        lableName: "全部",
      };
      hotLabels.unshift(tag_all);
      return hotLabels
        .concat(this.hotLableList.reverse())
        .filter((item) => typeof item === "object");
    },
    // 共同监听
    listenChange() {
      const { subjectId, level, labelId, pageSize } = this;
      return { subjectId, level, labelId, pageSize };
    },

    ...mapState({
      subjectList: (state) => state.sj.subjectArr,
    }),
    subjectArr() {
      return [
        {
          id: null,
          subjectName: "全部",
        },
      ].concat(this.subjectList);
    },
  },
  data() {
    return {
      // 重要id
      subjectId: null, // 当前学科id
      level: null, // 当前学科等级
      labelId: null, // 标签id
      pageSize: 30, // 单页项数
      pageNum: 1, // 页码
      // 序号变化
      curLevelIndex: null,
      // curLableIndex: 0,
      // curSubjectIndex: 0,
      // curPageIndex: 1,
      // 返回的数据
      // subjectArr: [],
      hotLableList: [],
      videoArr: [],
      totalCount: 0, // 查询返回的总数量
      // 其他方法属性
      expandHeight: false,
      expandHeightSecond: false,
      expandHeightThird: false,
    };
  },
  watch: {
    listenChange() {
      this.getVideoList();
    },
  },
  methods: {
    async getHotLabels(sid) {
      const res = await getHotLabels(sid);
      this.hotLableList = res.data;
    },
    async getVideoList(isResetPageNum = true) {
      // 是否重置页码
      isResetPageNum ? (this.pageNum = 1) : false;

      const res = await getSubjectStageCourses({
        subjectId: this.subjectId,
        level: this.curLevelIndex,
        labelId: this.labelId,
        pageNum: this.pageNum,
        pageSize: this.pageSize,
      });
      this.videoArr = res.data.list;
      this.totalCount = res.data.totalCount;
    },
    chooseSubject(e, i) {
      this.subjectId = e.id;
    },
    chooseLevel(level) {
      this.level = this.curLevelIndex = level;
    },
    changePage(idx) {
      this.pageNum = parseInt(idx);
      this.getVideoList(false);
    },
    chooseItemLabel(e) {
      if (e.target.tagName !== "LI") return;
      this.labelId = e.target.dataset.labelid;
    },
    jumptoDetailpage(v) {
      return;
      this.$router.push({
        path: `/video/${v.subjectId}/${v.id}`,
        query: this.$route.query,
      });
    },
    unfoldLabel() {
      this.expandHeight = !this.expandHeight;
    },
    unfoldLabelSecond() {
      this.expandHeightSecond = !this.expandHeightSecond;
    },
    unfoldLabelThird() {
      this.expandHeightThird = !this.expandHeightThird;
    },
  },
  mounted() {
    document.querySelector(".header").scrollIntoView();
    this.getVideoList();
  },
  created() {
    // 可能是根据标签栏组件跳转过来的，携带了labelId查询参数
    /*     if (this.$route.query.labelId) {
      this.labelId = this.$route.query.labelId;
      // 之后去掉这个多余的路由参数
      let newQuerys = JSON.parse(JSON.stringify(this.$route.query));
      delete newQuerys.labelId;
      this.$router.replace({ query: newQuerys });
    } */
    // 改为使用session
    if (sessionStorage.getItem("labelId")) {
      this.labelId = sessionStorage.getItem("labelId");
      sessionStorage.removeItem("labelId");
      this.expandHeightThird = true; // 打开标签列表
    }
    if (sessionStorage.getItem("subjectId")) {
      this.subjectId = sessionStorage.getItem("subjectId");
      sessionStorage.removeItem("subjectId");
    }
    this.getHotLabels();
  },
};
</script>

<style scoped>
@import url("~@/assets/css/classify.css");
</style>